import React, { Fragment, useState, useRef } from "react"
import RichText from "../../components/RichText"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "@yarno/thread"
import Meta from "../../components/Meta"
import Layout from "../../components/Layout"
import Container from "../../components/common/Container"
import SupportBreadcrumbs from "../../components/SupportBreadcrumbs"
import SliceMachine from "../../slices/SliceMachine"
import linkResolver from "../../utils/linkResolver"
import { graphql } from "gatsby"
import {
  SupportForm,
  FormGroup,
  RadioBigButtons,
  SupportFooter,
  SupportFooterImage,
  ArticleWell,
  AdditionalContext
} from "../../styles/support-styles"
import { useForm } from "react-hook-form"

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const SuggestedArticles = ({ articles, suggestedArticleByline }) => {
  /* We map over the documents passed to link resolver here to ensure
   * that the articles category is passed through. This isn't fantastic
   * as it can't really be put into the link resolver in the same way*/
  if (!articles || articles.length < 1) {
    return null
  }

  return (
    <ArticleWell>
      <strong>{suggestedArticleByline}</strong>
      <ul>
        {articles.map(article => {
          return (
            <li key={article?.document?.id}>
              <a
                target="_blank"
                href={linkResolver({
                  type: "support_article",
                  uid: article?.document?.data?.default_category?.uid,
                  aid: article?.document.uid
                })}>
                {article?.document?.data?.title?.text}
              </a>
            </li>
          )
        })}
      </ul>
    </ArticleWell>
  )
}

const FormFields = ({
  register,
  formField,
  suggestedArticles,
  suggestedArticleByline
}) => {
  const additionalFormFields = formField?.data?.body
  const additionalContext = formField?.data?.additional_context?.richText

  return (
    <div>
      <SuggestedArticles
        articles={suggestedArticles}
        suggestedArticleByline={suggestedArticleByline}
      />

      {additionalContext && (
        <AdditionalContext>
          <RichText render={additionalContext} />
        </AdditionalContext>
      )}

      <FormGroup twoColumn>
        <div>
          <label>Your first name</label>
          <input
            required
            type="text"
            name="firstName"
            {...register("firstName")}
          />
        </div>

        <div>
          <label>Your last name</label>
          <input
            required
            type="text"
            name="lastName"
            {...register("lastName")}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <label>Your email address</label>
        <input
          required
          type="email"
          name="emailAddress"
          {...register("emailAddress")}
        />
      </FormGroup>

      {additionalFormFields && (
        <SliceMachine body={additionalFormFields} register={register} />
      )}

      <FormGroup>
        <Button label="Contact support" type="submit" />
      </FormGroup>
    </div>
  )
}

const Template = ({ data, pageContext, location }) => {
  const { register, watch, setValue, handleSubmit } = useForm()
  const watchRole = watch("role", null)
  const watchFormField = watch("formField", null)

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  const [formSubmissionSuccessful, setFormSubmissionSuccessful] =
    useState(false)

  const bcrumb = [
    { name: "Support", link: "/support/" },
    { name: "Contact us", link: null }
  ]

  const {
    allPrismicSupportFormFields: { edges: allPrismicSupportFormFields },
    prismicSupportForm
  } = data
  const supportFormFields = allPrismicSupportFormFields.map(ff => ff.node)
  const learnerFormFields = supportFormFields.filter(
    ff => ff.data.role == "learners"
  )
  const managerFormFields = supportFormFields.filter(
    ff => ff.data.role == "manager or admins"
  )

  const formField = watchFormField
    ? supportFormFields.find(ff => ff.id == watchFormField)
    : null

  const suggestedArticles = formField?.data?.suggested_articles.map(
    a => a.article
  )

  const formFieldOptions = watchRole
    ? watchRole == "learner"
      ? learnerFormFields
      : managerFormFields
    : null

  const resetFormField = e => {
    if (watchFormField !== null) {
      setValue("formField", null)
    }
  }

  const generateMessage = ""

  const onSubmit = async data => {
    const fieldMappings = Object.assign(
      {},
      ...formField?.data?.body
        ?.filter(slice => {
          const specialFields = ["file", "file_upload"]
          return !specialFields.includes(slice.primary.field_name)
        })
        .map(slice => {
          return { [slice.primary.field_name]: slice.primary.label }
        })
    )
    let message = ""
    message += ""
    Object.keys(fieldMappings).forEach(key => {
      message += `<strong>${fieldMappings[key]}</strong><br />${
        data[key] ? data[key] : "-"
      }<br /><br />`
    })

    const subject = `I need help: ${formField.data.name}`
    let tags = formField?.data?.helpscout_tags.map(t => t.tag)
    let attachments = []

    const hasAttachmentField = formField?.data?.body?.find(
      slice => slice.primary.field_name == "file"
    )
    if (hasAttachmentField && data.file && data.file[0]) {
      const fileData = await getBase64(data.file[0])
      attachments = [
        {
          fileName: data.file[0].name,
          mimeType: data.file[0].type,
          data: fileData.split(",")[1]
        }
      ]
    }

    await fetch("/api/support-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        subject: subject,
        message: message,
        attachments: attachments,
        tags: tags
      })
    })
      .then(function (response) {
        if (response.ok) {
          console.log(response)
          return response.json()
        }
      })
      .then(json => {
        if (json.successful) {
          setFormSubmissionSuccessful(true)
          executeScroll()
        } else {
          alert("Something went wrong")
        }
      })
  }

  return (
    <Layout
      location={location}
      prismicLayout={data.prismicLayout}
      prismicSiteBanner={data.prismicSitebanner}
      hideSalesCTA>
      <Meta
        title={`Contact Yarno Support`}
        description={""}
        canonical={location.href}
      />

      <SupportBreadcrumbs bcrumb={bcrumb} />

      <Container readable>
        <div style={{ margin: "4rem 0" }} ref={myRef}>
          <RichText render={prismicSupportForm?.data?.title?.richText} />

          <div style={{ fontSize: "1.2em" }}>
            <RichText
              render={prismicSupportForm?.data.larger_introduction?.richText}
            />
          </div>

          {formSubmissionSuccessful ? (
            <p>Thanks. We'll get back to you as soon as we can.</p>
          ) : (
            <div>
              <p>
                <strong>Let's start by getting some details from you:</strong>
              </p>

              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <RadioBigButtons>
                    <input
                      type="radio"
                      id="learner"
                      name="role"
                      value="learner"
                      {...register("role")}
                    />
                    <label htmlFor="learner" onClick={resetFormField}>
                      <span>
                        I'm a <strong>Yarno Learner</strong>
                      </span>
                    </label>

                    <input
                      type="radio"
                      id="manager_or_admin"
                      name="role"
                      value="manager_or_admin"
                      {...register("role")}
                    />
                    <label htmlFor="manager_or_admin" onClick={resetFormField}>
                      <span>
                        I'm a <strong>Yarno manager</strong> or{" "}
                        <strong>administrator</strong>.
                      </span>
                    </label>
                  </RadioBigButtons>
                </FormGroup>

                {formFieldOptions && (
                  <FormGroup inline>
                    <label style={{ minWidth: "13rem" }}>I need help</label>
                    <select {...register("formField")}>
                      <option value="">Pick a support area</option>
                      {formFieldOptions.map((field, index) => {
                        if (field) {
                          return (
                            <option
                              key={`option-${index}`}
                              name="formField"
                              value={field.id}>
                              {field.data.name}
                            </option>
                          )
                        }
                      })}
                    </select>
                  </FormGroup>
                )}

                {watchFormField && watchFormField.length > 0 && (
                  <FormFields
                    register={register}
                    formField={formField}
                    suggestedArticles={suggestedArticles}
                    suggestedArticleByline={
                      prismicSupportForm?.data?.suggested_article_byline
                    }
                  />
                )}
              </form>
            </div>
          )}
        </div>
      </Container>
      <SupportFooter>
        <Container readable>
          <SupportFooterImage>
            <GatsbyImage
              image={getImage(prismicSupportForm?.data?.support_footer_image)}
              alt={prismicSupportForm?.data?.support_footer_image.alt}
            />
          </SupportFooterImage>
          <RichText
            linkResolver={linkResolver}
            render={prismicSupportForm?.data?.support_footer_bio?.richText}
          />
        </Container>
      </SupportFooter>
    </Layout>
  )
}

export default Template

export const postQuery = graphql`
  query {
    ...PrismicSiteBanner
    ...PrismicLayout
    ...ContactForm
    ...SupportFormFields
  }
`